header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: rgb(254, 52, 132);
  background: linear-gradient(
    300deg,
    rgba(254, 52, 132, 1) 0%,
    rgba(238, 165, 181, 1) 64%,
    rgba(255, 153, 143, 1) 100%
  );
}
